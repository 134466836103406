<template>
  <div id="app" :style="styleInfo">
    <img src="@/assets/images/logo.png" :class="isMourn == 'Y' ? 'logo-fixed filter-logo' : 'logo-fixed'" @click="clickLogo" />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { getFilter } from "@/api/home";
export default {
  components: { Footer },
  data() {
    return {
      styleInfo: "",
    }
  },
  computed: {
    isMourn() {      
      return this.$store.getters.getPageMourn;
    }
  },
  mounted() {
    this.language = localStorage.setItem("language","zh")
    this.getIsFilter();
    let ww = document.documentElement.clientWidth;
    if (ww >= 1366) return;
    this.styleInfo = `transform: scale(${ww / 1366}); transform-origin: top left;`;    
  },
  methods: {
    clickLogo() {
      if (this.$route.name != 'home') this.$router.push('/');
    },
    getIsFilter() {      
      getFilter().then(res => {
        this.$store.commit('setMourn', res.msg);
        if (this.$route.name == 'home') {         
          this.$store.commit('setPageMourn', res.msg);
        }   
      })
    },
  }
}

</script>
<style lang="less">
@font-face {
  font-family: 'SourceHan';
  src: url('./assets/fonts/SourceHanSansCN-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'CharacterSoul';
  src: url('./assets/fonts/字魂扁桃体.ttf') format('truetype');
}
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Source Han Sans;
  width: 100%;
  margin: 0;
  padding: 0;
  min-width: 1366px;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}
.logo-fixed {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 120px;
  height: 120px;
  z-index: 10;
  background: rgba(216, 216, 216, 0.01);
  cursor: pointer;
}
.filter-logo {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}
@keyframes spin {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 126, 20, 0.3);
  }

  25% {
    box-shadow: 0 0 0 2px rgba(255, 126, 20, 0.3);
  }

  50% {
    box-shadow: 0 0 0 4px rgba(255, 126, 20, 0.3);
  }

  75% {
    box-shadow: 0 0 0 2px rgba(255, 126, 20, 0.3);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 126, 20, 0.3);
  }
}

.animation-btn {
  animation: 1.6s spin linear infinite;
}
</style>
