<template>
  <div>
    <div class="last-wrap" :class="isMourn == 'Y' ? 'filter' : ''">
      <div class="lastItem" @click="ismake = true">
        <img src="../assets/images/appointment.png" />
        <p class="enReser" v-if="language == 'en'">Reservation</p>
        <p v-else>预约</p>
      </div>
    </div>
    <div class="mark-wrap" v-show="ismake">
      <div class="makeon-wrap" :class="isMourn == 'Y' ? 'filter' : ''">
        <div class="logo-wrap">
          <img v-if="language == 'zh'" class="reserv" src="../assets/images/makeon.png" />
          <img v-if="language == 'en'" class="enReserv" src="../assets/images/enMake.png" />
          <img src="../assets/images/close.png" class="close" @click="ismake = false" />
        </div>
        <div class="phoneNumber" v-for="(item, index) in telOneList" :key="index">
          <h4>{{ item.userName }}</h4>
          <p>{{ item.userPhone }}</p>
        </div>
        <div class="point">
          <img src="../assets/images/point.png" />
        </div>
        <div class="phoneList">
          <ul>
            <li v-for="(item, index) in telTwoList" :key="index">
              {{ item.userName }} ：{{ item.userPhone }}
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { getTelList } from "@/api/home";
export default {
  data() {
    return {
      ismake: false,
      info: {
        name: "",
        phone: ""
      },
      language: 'zh',
      telOneList:[],
      telTwoList:[]
    }
  },
  computed: {
    isMourn() {      
      return this.$store.getters.getPageMourn;
    }
  },
  mounted() {
    this.language = localStorage.getItem('language');
    this.getTel()//获取联系电话
  },
  methods: {
    getTel() {
      getTelList().then(res => {
        if (res.data.length > 0) {
          this.telOneList = res.data.splice(0, 1)
          this.telTwoList = res.data
        }
      })
    }
  }

}
</script>
<style lang="less" scoped>
.nav {
  position: fixed;
  top: 100px;
  right: 24px;
  z-index: 300;
  width: 80px;
  height: 656px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  padding: 8px;
  box-sizing: border-box;
}
.filter {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}
.img-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
  height: 544px;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.img-item {
  position: relative;

  &:hover .text-wrap {
    width: 210px;
  }

  &:hover .img-wrap {
    background: #FF7E14;
  }
}

.img-wrap {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;

  img {
    width: 40px;
    height: 40px;
  }

  &.last:hover {
    background: #FFFFFF;
  }
}

.text-wrap {
  box-sizing: border-box;
  position: absolute;
  height: 68px;
  line-height: 68px;
  font-size: 20px;
  color: #FF7E14;
  background: #FFFFFF;
  border-radius: 34px;
  top: -2px;
  right: -2px;
  z-index: 7;
  width: 0px;
  overflow: hidden;
  white-space: nowrap;
  transition: width 0.1s linear;

  span {
    padding-left: 24px;
    cursor: pointer;
  }
}

.mark-wrap {
  width: 100vw;
  height: 100vh;
  min-width: 1366px;
  position: fixed;
  top: 0px;
  right: -17px;
  z-index: 200;
  background: rgba(0, 0, 0, 0.3);
}

.makeon-wrap {
  position: absolute;
  width: 520px;
  height: 710px;
  background-image: url("../assets/images/appointmentBg.png");
  background-color: #ffffff;
  border-radius: 16px;
  top: 104px;
  right: 156px;
  z-index: 200;
  padding: 16px 32px;
  box-sizing: border-box;
}

.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px dashed #E5E5E5;
  margin-bottom: 24px;

  .close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.item {
  margin-bottom: 32px;
}

.group-wrap {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.group {
  width: 180px;
  height: 70px;
  border-radius: 8px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  cursor: pointer;

  p {
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    margin: 0;

    &.ren {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.active {
    background: #FFF7F1;

    p {
      color: #FF7E14;
    }
  }
}

.recr-wrap {
  display: flex;
  align-items: center;

  .group {
    margin-right: 12px;
    height: 56px;
    color: #333333;

    &.active {
      background: #FFF7F1;
      color: #FF7E14;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #343434;
  margin-bottom: 16px;
  margin-left: 16px;
  position: relative;

  &::before {
    content: "*";
    position: absolute;
    left: -12px;
    top: 4px;
    font-size: 18px;
    color: #FF4949;
  }
}

.btn {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 24px;
  line-height: 64px;
  text-align: center;
  width: 564px;
  height: 64px;
  border-radius: 32px;
  background: #FF7E14;
  cursor: pointer;
}

/deep/ .el-input__inner {
  font-family: Source Han Sans CN;
  line-height: 56px;
  height: 56px;
  border-radius: 8px;
  color: #343434;
  font-size: 20px;
}

.lastItem {
  width: 80px;
  height: 96px;
  border-radius: 20px;
  background: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 8px 16px 0px rgba(255, 126, 20, 0.1);
}

.lastItem img {
  width: 40px;
  height: 40px;
  margin: 0px 0 8px 0;
}

.lastItem p {
  font-size: 20px;
  color: #FF7E14;
  margin: 0;
}

.phoneNumber {
  width: 100%;
  margin: 0;
}

.phoneNumber h4 {
  font-size: 24px;
  font-weight: bold;
  color: #343434;
  margin: 0 0 0 0;
}

.phoneNumber p {
  margin: 10px 0 10px 0;
  font-size: 32px;
  font-weight: bold;
  color: #FF7E14;
}

.phoneList {
  width: 100%;
  margin: 0px 0 0 0;
}

.phoneList ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 420px;
  overflow-y: scroll;
}

.phoneList ul li {
  font-size: 20px;
  color: #343434;
  line-height: 60px;
}

::-webkit-scrollbar {
  display: none;
}

.point {
  width: 8px;
  height: 40px;
  margin: 10px 0;
}
.enReser{
  font-size: 14px !important;
}
.reserv{
  width: 257px;
  height: 40px;
}
.enReserv{
  width: 401px;
  height: 40px;
}
</style>