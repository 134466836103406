import axios from 'axios'  //引入axios

const baseURL = '/api'  //项目访问的地址和前缀

import { Message } from 'element-ui'

//创建一个新的axios实例
const service = axios.create({
    baseURL: baseURL,
    timeout: 20000    //设置超时时间，超过该时间就不会发起请求
})

service.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

let language = localStorage.getItem('language')

if (!language) {
    language = 'zh'
    localStorage.setItem('language', 'zh')
}
// 请求拦截器，在发送请求前要做的事，例如设置请求头，统一的请求参数等
service.interceptors.request.use(
    config => {
        config.headers['language'] = language;
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    })

// 响应拦截器，对请求后等到响应的数据进行处理
service.interceptors.response.use(
   
    response => {
        
        const msg = response.data.msg
       // console.log('++++',response.data)
        // 请求成功处理
        if (response.data.code == 200) {
            return response.data
        }
        else if (response.data.code == 500) {
            Message({ message: msg, type: 'error' })
            return Promise.reject(new Error(msg || 'Error'))
        } 
        else if (response.data.code == 503) {
            Message({ message: msg, type: 'error' })
            return Promise.reject(new Error(msg || 'Error'))
        }  

        else {
            return response.data
        }

    },
    error => {
        // 请求失败处理
        console.log('err' + error)
    }
)

// 对外暴露
export default service