import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '../store/index';
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/carousel",
    name: "carousel",
    component: HomeView,
  },
  {
    path: "/data",
    name: "data",
    component: HomeView,
  },
  {
    path: "/study",
    name: "study",
    component: HomeView,
  },
  {
    path: "/consult",
    name: "consult",
    component: HomeView,
  },
  {
    path: "/scenic",
    name: "scenic",
    component: HomeView,
  },
  {
    path: "/attract",
    name: "attract",
    component: HomeView,
  },
  {
    path: "/buddy",
    name: "buddy",
    component: HomeView,
  },
  {
    path: "/ScenicList",
    name: "scenicList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ScenicList.vue"),
  },
  {
    path: "/ScenicDetails",
    name: "scenicDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ScenicDetails.vue"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../views/activity/activityView.vue"),
  },
  {
    path: "/activityDetail",
    name: "activityDetail",
    component: () => import("../views/activity/activityDetailView.vue"),
  },
  {
    path: "/further",
    name: "further",
    component: () => import("../views/further/furtherView.vue"),
  },
  {
    path: "/further",
    name: "further",
    component: () => import("../views/further/furtherView.vue"),
  },
  {
    path: "/displayContent",
    name: "displayContent",
    component: () => import("../views/displayContent/displayContent.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => { 
  if (!from.name) return next();
  if (to.name == 'home') {
    if (store.getters.getIsMourn == 'Y') store.commit('setPageMourn', store.getters.getIsMourn);
  } else {
    if (store.getters.getIsMourn == 'Y') store.commit('setPageMourn', 'N');
  }
  next();
});
export default router;
