import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMourn: "N",
    pageMourn: "N",
  },
  getters: {
    getIsMourn(state) {
      return state.isMourn
    },
    getPageMourn(state) {
      return state.pageMourn
    }
  },
  mutations: {
    setMourn(state, type) {    
      state.isMourn = type;
    },
    setPageMourn(state, type) {
      state.pageMourn = type;
    }
  },
  actions: {
  },
  modules: {
  }
})
